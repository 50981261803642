// File: src/App.js

import React from 'react';
import { HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import Navbar from "./components/Navbar";
import Header from "./components/Header";
import About from "./components/About";
import Services from "./components/Services";
import Skills from "./components/Skills";
import Testimonial from "./components/Testimonial";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Login from "./components/Login";
import AdminDashboard from "./components/AdminDashboard";
import Dashboard from "./components/Dashboard";
import EventsPage from "./components/EventsPage";
import PaymentSuccess from "./components/PaymentSuccess";
import PaymentConfirmation from "./components/PaymentConfirmation";

// Consolidated home page component
const Home = () => (
  <>
    <Header id="header" />
    <About id="about" />
    <Services id="services" />
    <Skills id="skills" />
    <Testimonial id="testimonials" />
    <Contact id="contacter" />
    <Footer />
  </>
);

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        {/* Main Landing Page */}
        <Route path="/" element={<Home />} />
        {/* Authentication */}
        <Route path="/login" element={<Login />} />
        {/* Admin Dashboard */}
        <Route path="/admin_dashboard" element={<AdminDashboard />} />
        {/* User Dashboard */}
        <Route path="/dashboard" element={<Dashboard />} />
        {/* Public Events Page */}
        <Route path="/events" element={<EventsPage />} />
        {/* Payment Flow */}
        <Route path="/payment-success" element={<PaymentSuccess />} />
        <Route path="/payment-confirmation" element={<PaymentConfirmation />} />
        {/* Fallback to Home (avoiding 404 pages) */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
}

export default App;
