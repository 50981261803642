import React, { useState, useEffect } from 'react';
import './Events.css';
import { supabase } from '../../supabaseClient';
import { useNavigate } from 'react-router-dom';
import eventsVideo from '../../images/events.MP4';

const EventsPage = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [dateFilter, setDateFilter] = useState('');
  const [expandedEventId, setExpandedEventId] = useState(null);

  // Fetch events on mount
  const fetchEvents = async () => {
    try {
      const { data, error } = await supabase
        .from('events')
        .select('*')
        .order('event_date', { ascending: true });
      if (error) throw error;
      setEvents(data || []);
    } catch (err) {
      console.error('Error fetching events:', err.message);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  // Filter logic
  const filteredEvents = dateFilter
    ? events.filter((ev) => ev.event_date === dateFilter)
    : events;

  // Expand/collapse event details
  const handleCardClick = (eventId) => {
    setExpandedEventId((prev) => (prev === eventId ? null : eventId));
  };

  // Registration handling: check if logged in, then redirect to Stripe link.
  const handleRegister = async (eventItem) => {
    const {
      data: { session },
      error,
    } = await supabase.auth.getSession();
    if (error) {
      console.error('Error checking session:', error.message);
      return;
    }
    if (!session) {
      alert('Commencez par vous connecter et vous pourrez ENSUITE vous inscrire. 1. Créer un compte  2. Inscription ');
      navigate('/login');
    } else {
      if (!eventItem.stripe_link) {
        alert("Aucun lien Stripe pour cet événement.");
        return;
      }
      window.location.href = eventItem.stripe_link;
    }
  };

  return (
    <div className="events-page">
      {/* Floating shapes for extra visual interest */}
      <div className="floating-container">
        <div
          className="floating-shape"
          style={{ top: '10%', left: '5%', width: '50px', height: '50px' }}
        ></div>
        <div
          className="floating-shape"
          style={{ top: '70%', left: '80%', width: '80px', height: '80px' }}
        ></div>
        <div
          className="floating-shape"
          style={{ top: '40%', left: '50%', width: '60px', height: '60px' }}
        ></div>
      </div>

      {/* Banner Section */}
      <div className="banner-wrapper">
        <video
          autoPlay
          muted
          loop
          playsInline
          webkit-playsinline
          className="banner-video"
        >
          <source src={eventsVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="banner-content">
          <h1 className="events-title">Nos Événements</h1>
          <div className="events-filter">
            <label htmlFor="dateFilter" className="filter-label">
              Filtrer par date:
            </label>
            <input
              type="date"
              id="dateFilter"
              className="filter-date-input"
              value={dateFilter}
              onChange={(e) => setDateFilter(e.target.value)}
            />
          </div>
        </div>
      </div>

      {/* Events Grid */}
      <div className="events-grid">
        {filteredEvents.length === 0 ? (
          <p className="no-events-text">Aucun événement trouvé.</p>
        ) : (
          filteredEvents.map((eventItem) => {
            const isExpanded = expandedEventId === eventItem.id;
            return (
              <div
                key={eventItem.id}
                className={`event-card ${isExpanded ? 'expanded' : ''}`}
                onClick={() => handleCardClick(eventItem.id)}
              >
                <div className="card-header">
                  <h3 className="event-title">{eventItem.title}</h3>
                  <p className="event-date">
                    <strong>Date:</strong> {eventItem.event_date || 'N/A'}
                  </p>
                </div>

                <div className="card-summary">
                  {eventItem.time && (
                    <p className="event-time">
                      <strong>Heure:</strong> {eventItem.time}
                    </p>
                  )}
                  {eventItem.location && (
                    <p className="event-location">
                      <strong>Lieu:</strong> {eventItem.location}
                    </p>
                  )}
                  {!isExpanded && (
                    <p className="event-description">
                      {eventItem.description?.slice(0, 80)}...
                    </p>
                  )}
                </div>

                {isExpanded && (
                  <div className="event-details">
                    <div className="detail-row">
                      <span className="detail-label">Description:</span>
                      <span>{eventItem.description}</span>
                    </div>
                    <div className="detail-row">
                      <span className="detail-label">Prix:</span>
                      <span>{eventItem.price} $</span>
                    </div>
                    <div className="detail-row">
                      <span className="detail-label">Invité:</span>
                      <span>{eventItem.invitee || 'Non spécifié'}</span>
                    </div>
                    <div className="detail-row">
                      <span className="detail-label">Deadline:</span>
                      <span>{eventItem.registration_deadline}</span>
                    </div>
                  </div>
                )}

                <button
                  className="register-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRegister(eventItem);
                  }}
                >
                  S'inscrire
                </button>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default EventsPage;
