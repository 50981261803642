// File: src/components/Login/index.jsx

import React, { useState } from 'react';
import { supabase } from '../../supabaseClient';
import './Login.css';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  // State to toggle between login and sign-up views
  const [isLogin, setIsLogin] = useState(true);
  // Form fields
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [authMessage, setAuthMessage] = useState('');

  // React Router navigation hook
  const navigate = useNavigate();

  // Toggle between Login and Sign-Up modes
  const handleToggle = () => {
    setEmail('');
    setPassword('');
    setFullName('');
    setAuthMessage('');
    setIsLogin(!isLogin);
  };

  // --- LOGIN FUNCTION ---
  const handleLogin = async (e) => {
    e.preventDefault();
    setAuthMessage('');

    try {
      // Sign in with email and password
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      if (error) throw error;

      setAuthMessage('Login successful!');
      const user = data.user;
      if (!user) {
        setAuthMessage('Login successful! Please check your email for confirmation.');
        return;
      }

      // Query the profiles table to get the role for this user
      const { data: profileData, error: profileError } = await supabase
        .from('profiles')
        .select('role')
        .eq('id', user.id)
        .single();

      if (profileError) {
        throw profileError;
      }

      const role = profileData.role;
      // Navigate based on role
      if (role === 'admin') {
        navigate('/admin_dashboard');
      } else {
        navigate('/dashboard');
      }
    } catch (err) {
      console.error(err);
      setAuthMessage(err.error_description || err.message);
    }
  };

  // --- SIGN-UP FUNCTION ---
  const handleSignUp = async (e) => {
    e.preventDefault();
    setAuthMessage('');

    try {
      // Step 1: Create a new user in Supabase Auth.
      const { data, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: { displayName: fullName },
        },
      });
      if (error) throw error;

      const user = data.user;
      if (!user) {
        setAuthMessage('Sign-up successful! Please check your email to confirm your account.');
        return;
      }

      // Step 2: Insert a row into the profiles table with a default role of "user".
      const { error: profileError } = await supabase
        .from('profiles')
        .insert([
          {
            id: user.id,
            full_name: fullName,
            email: email,
            role: 'user'
          },
        ]);
      if (profileError) throw profileError;

      // Notify the user and prompt them to log in.
      setAuthMessage('Sign-up successful! You can now log in.');
    } catch (err) {
      console.error(err);
      setAuthMessage(err.error_description || err.message);
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2>{isLogin ? 'Login' : 'Sign Up'}</h2>
        <form onSubmit={isLogin ? handleLogin : handleSignUp}>
          {/* Only show the full name field for sign-up */}
          {!isLogin && (
            <>
              <label htmlFor="fullName">Full Name</label>
              <input
                type="text"
                id="fullName"
                placeholder="Your full name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                required
              />
            </>
          )}

          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            placeholder="Your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            placeholder="Your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />

          <button type="submit" className="login-btn">
            {isLogin ? 'Login' : 'Sign Up'}
          </button>
        </form>

        {authMessage && <p className="auth-message">{authMessage}</p>}

        <p className="toggle-text">
          {isLogin ? 'No account yet? ' : 'Already have an account? '}
          <span onClick={handleToggle}>
            {isLogin ? 'Sign Up' : 'Login'}
          </span>
        </p>
      </div>
    </div>
  );
};

export default Login;
