import React, { useState, useEffect } from 'react';
import { supabase } from '../../supabaseClient';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';

const Dashboard = () => {
  const [registrations, setRegistrations] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [sessionUser, setSessionUser] = useState(null);

  // Fetch user session and then their registrations
  useEffect(() => {
    const getSessionAndRegistrations = async () => {
      const { data: { session }, error } = await supabase.auth.getSession();
      if (error) {
        console.error('Error fetching session:', error.message);
        return;
      }
      if (!session) {
        navigate('/login');
      } else {
        setSessionUser(session.user);
        fetchRegistrations(session.user.id);
      }
    };
    getSessionAndRegistrations();
  }, [navigate]);

  // Fetch registrations for the logged in user,
  // joining event details from the events table.
  const fetchRegistrations = async (userId) => {
    try {
      const { data, error } = await supabase
        .from('registrations')
        .select(`
          id,
          created_at,
          events (
            title,
            event_date,
            time,
            location,
            description
          )
        `)
        .eq('user_id', userId)
        .order('created_at', { ascending: false });
      if (error) throw error;
      setRegistrations(data || []);
    } catch (err) {
      console.error('Error fetching registrations:', err.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="dashboard-container">
        <h2>Loading...</h2>
      </div>
    );
  }

  return (
    <div className="dashboard-container">
      <h1>Mes événements</h1>
      {registrations.length === 0 ? (
        <p>You are not registered for any events.</p>
      ) : (
        <div className="dashboard-grid">
          {registrations.map((reg) => (
            <div key={reg.id} className="dashboard-card">
              <h3>{reg.events.title}</h3>
              <p><strong>Date:</strong> {reg.events.event_date}</p>
              <p><strong>Heure:</strong> {reg.events.time}</p>
              <p><strong>Location:</strong> {reg.events.location}</p>
              <p className="description">
                {reg.events.description}
              </p>
              <p className="confirmation">
                <strong>Numéro de confirmation:</strong> {reg.id}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dashboard;
