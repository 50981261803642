// File: src/components/AdminDashboard/AdminDashboard.jsx
import React, { useState, useEffect } from 'react';
import './AdminDashboard.css';
import { supabase } from '../../supabaseClient';
import { FaCopy } from 'react-icons/fa';

const AdminDashboard = () => {
  // State to hold fetched events
  const [events, setEvents] = useState([]);
  // Filter for event_date
  const [dateFilter, setDateFilter] = useState('');
  // Toggle to show/hide the Create Event form
  const [showCreateForm, setShowCreateForm] = useState(false);

  // New event form fields
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [stripeLink, setStripeLink] = useState('');
  const [eventDate, setEventDate] = useState('');
  const [registrationDeadline, setRegistrationDeadline] = useState('');
  const [time, setTime] = useState('');
  const [invitee, setInvitee] = useState('');
  const [location, setLocation] = useState('');

  // Track which event is expanded to show details
  const [expandedEventId, setExpandedEventId] = useState(null);

  // Store registrations for each event (eventId => array of registrations)
  const [eventRegistrations, setEventRegistrations] = useState({});

  // 1) Fetch events from Supabase
  const fetchEvents = async () => {
    try {
      const { data, error } = await supabase
        .from('events')
        .select('*')
        .order('event_date', { ascending: true });

      if (error) throw error;
      setEvents(data || []);
    } catch (err) {
      console.error('Error fetching events:', err.message);
    }
  };

  // 2) useEffect to run fetchEvents on mount
  useEffect(() => {
    fetchEvents();
    // eslint-disable-next-line
  }, []);

  // 3) Filter logic: if dateFilter is set, only show events matching that date
  const filteredEvents = dateFilter
    ? events.filter((ev) => ev.event_date === dateFilter)
    : events;

  // 4) Handle form submission to create a new event
  const handleCreateEvent = async (e) => {
    e.preventDefault();
    try {
      // Insert new event (without event_url)
      let { data: inserted, error: insertError } = await supabase
        .from('events')
        .insert([
          {
            title,
            description,
            price: price ? Number(price) : null,
            stripe_link: stripeLink,
            event_date: eventDate || null,
            registration_deadline: registrationDeadline || null,
            time: time || null,
            invitee: invitee || '',
            location: location || ''
          },
        ])
        .select();

      if (insertError) throw insertError;
      if (!inserted || inserted.length === 0) {
        throw new Error('No event returned after insertion.');
      }

      // Grab the new event's ID and update event_url
      const newEvent = inserted[0];
      const eventId = newEvent.id;
      const finalUrl = `https://yencommunity.ca/#/payment-success?event_id=${eventId}`;

      let { error: updateError } = await supabase
        .from('events')
        .update({ event_url: finalUrl })
        .eq('id', eventId);

      if (updateError) throw updateError;

      // Clear form
      setTitle('');
      setDescription('');
      setPrice('');
      setStripeLink('');
      setEventDate('');
      setRegistrationDeadline('');
      setTime('');
      setInvitee('');
      setLocation('');
      setShowCreateForm(false);
      fetchEvents();
    } catch (err) {
      console.error('Error creating event:', err.message);
    }
  };

  // 5) Fetch registrations for a specific event
  const fetchRegistrationsForEvent = async (eventId) => {
    try {
      const { data: registrations, error } = await supabase
        .from('registrations')
        .select('id, user_id, event_id')
        .eq('event_id', eventId);

      if (error) throw error;

      // For each registration, get the user from 'profiles'
      const registrationsWithProfile = await Promise.all(
        (registrations || []).map(async (reg) => {
          const { data: profileData, error: profileError } = await supabase
            .from('profiles')
            .select('id, full_name, email')
            .eq('id', reg.user_id)
            .single();

          if (profileError) {
            console.error('Error fetching profile:', profileError.message);
            return { ...reg, profile: null };
          }
          return { ...reg, profile: profileData };
        })
      );
      setEventRegistrations((prev) => ({
        ...prev,
        [eventId]: registrationsWithProfile,
      }));
    } catch (err) {
      console.error('Error fetching registrations:', err.message);
    }
  };

  // Toggle expanded card
  const handleCardClick = (eventId) => {
    if (expandedEventId === eventId) {
      setExpandedEventId(null);
    } else {
      setExpandedEventId(eventId);
      if (!eventRegistrations[eventId]) {
        fetchRegistrationsForEvent(eventId);
      }
    }
  };

  // 6) Handle copying the event_url
  const handleCopyEventUrl = (eventObj) => {
    if (!eventObj.event_url) {
      alert('No event_url set for this event!');
      return;
    }
    navigator.clipboard.writeText(eventObj.event_url)
      .then(() => alert('Event URL copied to clipboard!'))
      .catch((err) => {
        console.error('Failed to copy: ', err);
        alert('Failed to copy the event URL.');
      });
  };

  // 7) Handle deleting an event
  const handleDeleteEvent = async (eventId, e) => {
    e.stopPropagation();
    if (!window.confirm('Are you sure you want to delete this event?')) return;
    try {
      const { error } = await supabase
        .from('events')
        .delete()
        .eq('id', eventId);

      if (error) throw error;
      if (expandedEventId === eventId) setExpandedEventId(null);
      fetchEvents();
    } catch (err) {
      console.error('Error deleting event:', err.message);
      alert('Error deleting event.');
    }
  };

  // 8) Handle deleting a registration
  const handleDeleteRegistration = async (registrationId, eventId, e) => {
    e.stopPropagation();
    if (!window.confirm('Are you sure you want to delete this registration?')) return;
    try {
      const { error } = await supabase
        .from('registrations')
        .delete()
        .eq('id', registrationId);
      if (error) throw error;
      // Refresh registrations for the event after deletion
      fetchRegistrationsForEvent(eventId);
    } catch (err) {
      console.error('Error deleting registration:', err.message);
      alert('Error deleting registration.');
    }
  };

  return (
    <div className="admin-dashboard">
      <h1>Admin Dashboard</h1>

      {/* Filter Section */}
      <div className="filter-section">
        <label htmlFor="dateFilter">Filter by Event Date:</label>
        <input
          type="date"
          id="dateFilter"
          value={dateFilter}
          onChange={(e) => setDateFilter(e.target.value)}
        />
      </div>

      {/* Create Event Button & Form */}
      <button
        className="add-project-btn"
        onClick={() => setShowCreateForm((prev) => !prev)}
      >
        {showCreateForm ? 'Close Form' : 'Create Event'}
      </button>

      {showCreateForm && (
        <form className="create-event-form" onSubmit={handleCreateEvent}>
          <h2>Create a New Event</h2>
          <label>Title</label>
          <input
            type="text"
            placeholder="Event title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />

          <label>Description</label>
          <textarea
            placeholder="Short description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />

          <label>Price</label>
          <input
            type="number"
            placeholder="Event price"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />

          <label>Stripe Link</label>
          <input
            type="text"
            placeholder="Stripe checkout link"
            value={stripeLink}
            onChange={(e) => setStripeLink(e.target.value)}
          />

          <label>Event Date</label>
          <input
            type="date"
            value={eventDate}
            onChange={(e) => setEventDate(e.target.value)}
          />

          <label>Registration Deadline</label>
          <input
            type="date"
            value={registrationDeadline}
            onChange={(e) => setRegistrationDeadline(e.target.value)}
          />

          <label>Time</label>
          <input
            type="time"
            value={time}
            onChange={(e) => setTime(e.target.value)}
          />

          <label>Invitee</label>
          <input
            type="text"
            placeholder="Guest speaker or VIP"
            value={invitee}
            onChange={(e) => setInvitee(e.target.value)}
          />

          <label>Location</label>
          <input
            type="text"
            placeholder="Event location"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />

          <button type="submit" className="create-btn">
            Create Event
          </button>
        </form>
      )}

      {/* Display Events */}
      <div className="project-list">
        {filteredEvents.length === 0 ? (
          <p className="no-events">No events found.</p>
        ) : (
          filteredEvents.map((eventObj) => {
            const isExpanded = expandedEventId === eventObj.id;
            const registrations = eventRegistrations[eventObj.id] || [];
            return (
              <div
                key={eventObj.id}
                className={`project-item ${isExpanded ? 'expanded' : ''}`}
                onClick={() => handleCardClick(eventObj.id)}
              >
                <div className="project-item-header">
                  <h3>{eventObj.title}</h3>
                  <button
                    className="delete-btn"
                    onClick={(e) => handleDeleteEvent(eventObj.id, e)}
                  >
                    &#x2715;
                  </button>
                </div>

                {/* Expandable Details */}
                <div className="event-details">
                  <div className="detail-row">
                    <span className="detail-label">Description:</span>
                    <span>{eventObj.description}</span>
                  </div>
                  <div className="detail-row">
                    <span className="detail-label">Price:</span>
                    <span>{eventObj.price}</span>
                  </div>
                  <div className="detail-row">
                    <span className="detail-label">Date:</span>
                    <span>{eventObj.event_date}</span>
                  </div>
                  <div className="detail-row">
                    <span className="detail-label">Time:</span>
                    <span>{eventObj.time}</span>
                  </div>
                  <div className="detail-row">
                    <span className="detail-label">Invitee:</span>
                    <span>{eventObj.invitee}</span>
                  </div>
                  <div className="detail-row">
                    <span className="detail-label">Deadline:</span>
                    <span>{eventObj.registration_deadline}</span>
                  </div>
                  <div className="detail-row">
                    <span className="detail-label">Location:</span>
                    <span>{eventObj.location}</span>
                  </div>
                  {eventObj.stripe_link && (
                    <div className="detail-row">
                      <span className="detail-label">Stripe Link:</span>
                      <a
                        href={eventObj.stripe_link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {eventObj.stripe_link}
                      </a>
                    </div>
                  )}
                  {eventObj.event_url && (
                    <div className="event-url">
                      <span className="detail-label">Event URL:</span>
                      <span className="url-text">{eventObj.event_url}</span>
                      <button
                        className="copy-btn"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCopyEventUrl(eventObj);
                        }}
                      >
                        <FaCopy />
                      </button>
                    </div>
                  )}

                  {/* Registration List with Count */}
                  <div className="registration-list">
                    <h4>Registrations:</h4>
                    <p className="registration-count">
                      Total Registrations: {registrations.length}
                    </p>
                    {registrations.length === 0 ? (
                      <p className="no-registrations">No registrations yet.</p>
                    ) : (
                      registrations.map((reg) => (
                        <div key={reg.id} className="registrant-item">
                          {reg.profile ? (
                            <>
                              <p>
                                <strong>Name:</strong> {reg.profile.full_name}
                              </p>
                              <p>
                                <strong>Email:</strong> {reg.profile.email}
                              </p>
                            </>
                          ) : (
                            <p>No profile found for user_id: {reg.user_id}</p>
                          )}
                          <button
                            className="delete-registration-btn"
                            onClick={(e) =>
                              handleDeleteRegistration(reg.id, eventObj.id, e)
                            }
                          >
                            Delete Registration
                          </button>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;
