// File: src/components/Navbar/Navbar.jsx

import React, { useEffect, useState, useRef } from 'react';
import "./Navbar.css";
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import {
  FaBarsStaggered,
  FaInstagram,
  FaXmark,
  FaCircleUser // Changed icon import
} from "react-icons/fa6";
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import logo from '../../images/logo.png';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [visible, setVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(window.scrollY);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    if (currentScrollY > lastScrollY && currentScrollY > 100) {
      setVisible(false);
    } else if (currentScrollY < lastScrollY) {
      setVisible(true);
    }
    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY]);

  const container = useRef(null);
  gsap.registerPlugin(useGSAP);

  useEffect(() => {
    if (visible) {
      gsap.fromTo(
        ".navbar__container",
        { y: -250, width: '100%' },
        { y: 0, top: 0, zIndex: 100 }
      );
    }
  }, [visible]);

  useGSAP(() => {
    const timeline = gsap.timeline();
    timeline.from(".tab__item", { opacity: 0, stagger: 0.5 });
  }, { scope: container });

  return (
    <nav className={`navbar__container ${visible ? "visible" : "hidden"}`} ref={container}>
      {showSidebar && (
        <div className='overlay' onClick={() => setShowSidebar(false)}></div>
      )}

      <div className="logo__container">
        <Link to="/" onClick={() => setShowSidebar(false)}>
          <img src={logo} alt="Logo" className="logo" />
        </Link>
      </div>

      <div className={`tab__group ${showSidebar ? 'show' : ''}`}>
        <span
          className="icon__container close__btn"
          onClick={() => setShowSidebar(!showSidebar)}
        >
          <FaXmark />
        </span>

        <Link to="/" className="tab__item" onClick={() => setShowSidebar(false)}>
          Accueil
        </Link>

        <ScrollLink
          activeClass='active'
          className='tab__item'
          to='about'
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          À propos
        </ScrollLink>

        <ScrollLink
          activeClass='active'
          className='tab__item'
          to='services'
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Services
        </ScrollLink>

        <ScrollLink
          activeClass='active'
          className='tab__item'
          to='contact'
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Rejoindre
        </ScrollLink>

        <Link
          to="/events"
          className="tab__item"
          onClick={() => setShowSidebar(false)}
          style={{ color: 'inherit', textDecoration: 'none' }}
        >
          Événements
        </Link>
      </div>

      <div className="nav__buttons__group">
        <Link
          to="/login"
          className="btn btn__secondary"
          style={{ marginRight: '8px' }}
          onClick={() => setShowSidebar(false)}
        >
          <FaCircleUser />
        </Link>

        <a
          href="https://www.instagram.com/yencommunaute..."
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn__primary"
        >
          <FaInstagram />
        </a>

        <FaBarsStaggered
          className="menu"
          onClick={() => setShowSidebar(!showSidebar)}
        />
      </div>
    </nav>
  );
};

export default Navbar;
