import React, { useEffect, useState } from 'react';
import { supabase } from '../../supabaseClient';
import { useSearchParams, useNavigate } from 'react-router-dom';
import './PaymentSuccess.css';

const PaymentSuccess = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // States
  const [eventId, setEventId] = useState(null);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [message, setMessage] = useState('');
  const [sessionUser, setSessionUser] = useState(null);
  const [isRegistered, setIsRegistered] = useState(false);

  /**
   * We'll do everything in ONE useEffect to minimize
   * the risk of double calls, and to keep the logic
   * in one place.
   */
  useEffect(() => {
    (async function registerAutomatically() {
      // 1) Retrieve event_id from URL
      const eid = searchParams.get('event_id');
      setEventId(eid);

      // 2) Get session
      const {
        data: { session },
        error: sessionError,
      } = await supabase.auth.getSession();

      if (sessionError) {
        console.error('Error fetching session:', sessionError.message);
      }

      // If no session => show message and stop
      if (!session) {
        setMessage('Vous devez vous connecter pour confirmer.');
        return;
      } else {
        setSessionUser(session.user);
      }

      // 3) If no eventId => stop
      if (!eid) {
        alert("Aucun ID d'événement spécifié.");
        return;
      }

      // 4) Fetch event details (optional: you can do this in parallel or after)
      try {
        const { data, error } = await supabase
          .from('events')
          .select('id, title, description, event_date, time')
          .eq('id', eid)
          .single();

        if (error) {
          console.error('Error fetching event:', error.message);
        } else {
          setCurrentEvent(data);
        }
      } catch (err) {
        console.error('Error:', err.message);
      }

      // 5) Check if user is already registered
      try {
        // Use maybeSingle() to avoid throwing an error if no row is found
        const { data: existingRegistration, error: regError } = await supabase
          .from('registrations')
          .select('id')
          .eq('user_id', session.user.id)
          .eq('event_id', eid)
          .maybeSingle();

        if (regError) {
          // If there is some real error (not just 'no rows'), log it
          console.error('Error checking existing registration:', regError);
          // You can decide to stop or continue
        }

        // If there's a record, user is already registered
        if (existingRegistration) {
          setIsRegistered(true);
          alert('Vous êtes déjà inscrit à cet événement !');
          navigate('/dashboard');
          return;
        }
      } catch (err) {
        console.error('Error verifying registration:', err.message);
        // If we can't verify, better to stop to avoid possible duplicates
        return;
      }

      // 6) Insert new registration if none exists
      try {
        const { data, error } = await supabase
          .from('registrations')
          .insert([{ user_id: session.user.id, event_id: eid }])
          .select();

        if (error) throw error;
        if (!data || data.length === 0) {
          throw new Error("Impossible de récupérer l'ID de l'inscription.");
        }

        setIsRegistered(true);
        alert('Inscription confirmée !');
        navigate('/dashboard');
      } catch (err) {
        console.error('Error inserting registration:', err.message);
        alert('Erreur lors de la confirmation.');
      }
    })();
    // We pass an empty array so it only runs once on mount
  }, [navigate, searchParams]);

  return (
    <div className="payment-success-container">
      <div className="payment-success-card">
        <h2>Confirmation de paiement</h2>

        {currentEvent ? (
          <div className="payment-success-details">
            <h3>{currentEvent.title}</h3>
            <p>
              <strong>Description:</strong>{' '}
              {currentEvent.description || 'Aucune description'}
            </p>
            <p>
              <strong>Date:</strong> {currentEvent.event_date || 'N/A'}
            </p>
            <p>
              <strong>Heure:</strong> {currentEvent.time || 'N/A'}
            </p>
          </div>
        ) : eventId ? (
          <p>Chargement des détails de l'événement...</p>
        ) : (
          <p>Aucun événement identifié.</p>
        )}

        {message && <p className="message">{message}</p>}

        {isRegistered ? (
          <p>Inscription terminée ou déjà existante.</p>
        ) : (
          <p>Inscription en cours…</p>
        )}
      </div>
    </div>
  );
};

export default PaymentSuccess;
