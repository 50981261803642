// File: src/components/PaymentConfirmation/index.jsx
import React, { useEffect, useState } from 'react';
import { supabase } from '../../supabaseClient';
import { useSearchParams, useNavigate } from 'react-router-dom';

const PaymentConfirmation = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [registrationId, setRegistrationId] = useState(null);
  const [registrationData, setRegistrationData] = useState(null); // joined data
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const regId = searchParams.get('reg_id');
    setRegistrationId(regId);

    if (regId) {
      fetchRegistration(regId);
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchRegistration = async (id) => {
    try {
      /* We'll do a nested select with the relationships:
         - events -> event data
         - profiles -> user data
         Make sure your foreign keys are recognized as "events" and "profiles" in Supabase.
      */
      const { data, error } = await supabase
        .from('registrations')
        .select(`
          id,
          created_at,
          events (
            title,
            event_date
          ),
          profiles (
            full_name
          )
        `)
        .eq('id', id)
        .single();

      if (error) throw error;
      setRegistrationData(data);
    } catch (err) {
      console.error('Error fetching registration:', err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleOk = () => {
    // Return to home
    navigate('/');
  };

  if (loading) {
    return (
      <div style={{
        minHeight: '100vh',
        background: '#121212',
        color: '#fff',
        padding: '2rem'
      }}>
        <h2>Chargement...</h2>
      </div>
    );
  }

  return (
    <div style={{
      minHeight: '100vh',
      backgroundColor: '#121212',
      color: 'white',
      padding: '2rem'
    }}>
      <h2>Confirmation d'inscription</h2>

      {!registrationId ? (
        <p>Aucun ID d'inscription spécifié.</p>
      ) : registrationData ? (
        <div style={{ marginTop: '1rem' }}>
          <p><strong>ID de l'inscription:</strong> {registrationData.id}</p>
          <p><strong>Nom complet:</strong> {registrationData.profiles?.full_name || 'Inconnu'}</p>
          <p><strong>Événement:</strong> {registrationData.events?.title || 'Inconnu'}</p>
          <p><strong>Date de l'événement:</strong> {registrationData.events?.event_date || 'N/A'}</p>
        </div>
      ) : (
        <p>Impossible de trouver cette inscription.</p>
      )}

      <button
        onClick={handleOk}
        style={{
          marginTop: '2rem',
          padding: '0.7rem 1.2rem',
          cursor: 'pointer',
          background: 'blue',
          border: 'none',
          borderRadius: '5px',
          color: '#fff'
        }}
      >
        OK
      </button>
    </div>
  );
};

export default PaymentConfirmation;
